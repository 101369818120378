<template>
  <div class="container">
    <p class="fs9 fwbold mb20">账号管理</p>
    <p class="fs8 cA3 mb20">创建时间：{{createTime}}</p>
    <p class="fs8 cA3 mb20 ">登录账号：{{loginPassword.value.username}} 
      <span class="cblue hover_pointer ml10" v-if="changeType == 'set'" @click="changePassword('set')">设置密码</span>
      <span class="cblue hover_pointer ml10" v-else @click="changePassword('change')">修改密码</span>
    </p>
    <el-dialog :title="changeType == 'set' ? '设置密码': '忘记密码'" 
               width="25%"
               :center="true"
               :modal-append-to-body="false"
               :visible="dialogTableVisible"
               @close="dialogClose">
      <div v-if="changeType !== 'set'">
        <el-steps :active="active" finish-status="success" align-center>
          <el-step title="安全验证"></el-step>
          <el-step title="设置新密码"></el-step>
          <el-step title="设置成功"></el-step>
        </el-steps>
        <div class="d-flex align-items-center w100p justify-content-center">
          
          <el-form :model="registerForm" ref="loginRef" v-if="active == 0">
            <el-form-item
              style="margin-top: 20px"
              prop="username"
              id="border-none"
            >
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                v-model="registerForm.username"
                size="large"
                style="width: 300px; border: none"
                :disabled="true"
                prefix-icon="el-icon-lock"
              ></el-input>
            </el-form-item>
            <el-form-item
              style="margin-top: 20px"
              prop="password"
              id="border-none"
            >
              <div class="flex-a-b-c">
                <el-input
                  type="password"
                  style="position: fixed; bottom: -9999px"
                />
                <el-input
                  v-model="verificationCode"
                  auto-complete="new-accounts"
                  size="large"
                  style="width: 160px; border: none"
                  placeholder="请输入验证码"
                  prefix-icon="el-icon-lock"
                ></el-input>
                <el-button
                  plain
                  type="primary"
                  :disabled="countDown !== 0 ? true : false"
                  size="large"
                  class="h34 pt10"
                  @click="getSendCode(registerForm.username)"
                  >{{
                    countDown ? `重新发送(${countDown})` : "获取验证码"
                  }}</el-button
                >
              </div>
            </el-form-item>
          </el-form>

          <el-form :model="registerForm" ref="loginRef" v-if="active == 1">
            <el-form-item
              style="margin-top: 20px"
              prop="username"
              id="border-none"
            >
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                v-model="passwordForm.newPassword"
                size="large"
                style="width: 300px; border: none"
                placeholder="请输入新密码"
                prefix-icon="el-icon-lock"
              ></el-input>
            </el-form-item>
            <el-form-item
              style="margin-top: 20px"
              prop="password"
              id="border-none"
            >
              <div class="flex-a-b-c">
                <el-input
                  type="password"
                  style="position: fixed; bottom: -9999px"
                />
                <el-input
                  v-model="passwordForm.repetitionPassword"
                  auto-complete="new-accounts"
                  size="large"
                  style="width: 300px; border: none"
                  placeholder="请重复新密码"
                  prefix-icon="el-icon-lock"
                ></el-input>
              </div>
            </el-form-item>
          </el-form>

          <el-form :model="registerForm" ref="loginRef" v-if="active == 3">
            <div class="flex-c-c h200 flex-column">
              <i class="el-icon-circle-check fs24
              "></i>
              <p class="fs10">设置成功</p>
            </div>
          </el-form>
          
        </div>
        <div class="d-flex w100p justify-content-center mt40">
          <el-button class="w300 " v-if="active !== 3" type="primary" @click="nextStep">下一步</el-button>
        </div>
      </div>
      <div  v-else> 
        <div class="flex-c-c">
          <el-form :model="registerForm" ref="loginRef">
            <el-form-item
              style="margin-top: 20px;"
              prop="username"
              id="border-none"
              class="posre "
            >
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                v-model="oldPassword"
                size="large"
                style="width: 300px; border: none"
                placeholder="请输入旧密码"
                prefix-icon="el-icon-lock"
              ></el-input>
              <div class="posab hover_pointer cblue"
                  style="right:0"
                  @click="changeTypeTap"
                  >忘记密码?</div>
            </el-form-item>
            <el-form-item
              style="margin-top: 40px"
              prop="username"
              id="border-none"
            >
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                v-model="setPasswordForm.newPassword"
                size="large"
                style="width: 300px; border: none"
                placeholder="请输入新密码"
                prefix-icon="el-icon-lock"
              ></el-input>
            </el-form-item>
            <el-form-item
              style="margin-top: 20px"
              prop="password"
              id="border-none"
            >
              <div class="flex-a-b-c">
                <el-input
                  type="password"
                  style="position: fixed; bottom: -9999px"
                />
                <el-input
                  v-model="setPasswordForm.repetitionPassword"
                  auto-complete="new-accounts"
                  size="large"
                  style="width: 300px; border: none"
                  placeholder="请重复新密码"
                  prefix-icon="el-icon-lock"
                ></el-input>
              </div>
            </el-form-item>
          </el-form>
          
        </div>
        <div class="d-flex w100p justify-content-center mt40">
          <el-button class="w300 " v-if="active !== 3" type="primary" @click="modifyPwd">确定</el-button>
        </div>

      </div>
      
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/login";
import utils from "@/utils/index"
import {queryEmpRouter} from '@/api/companyManage';
import { mapState,mapMutations } from 'vuex';
export default {
  name: "dashboard",
  data() {
    return {
      createTime:'',
      loginPassword:'',
      dialogTableVisible:false,
      countDown:0,
      changeType:'',
      active:0,
      registerForm: {
        username: "",
        password: "",
        companyName: "",
        companyId: "",
      },
      passwordForm:{
        newPassword:'',
        repetitionPassword:''
      },
      verificationCode:'',
      signature:'', //签名
      companyId:'',
      oldPassword:'' ,//旧密码
      setPasswordForm:{
        newPassword:'',
        repetitionPassword:''
      }
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    
  },
  created(){
    this.createTime = localStorage.getItem("createTime")
    this.loginPassword = JSON.parse(localStorage.getItem("password"))[0]
    if(this.loginPassword.value && this.loginPassword.value.phone){
      this.registerForm.username =  this.loginPassword.value.phone
      
    }else{
      this.registerForm.username =  JSON.parse(localStorage.getItem('codePhoen'))
    }
    api.fetchCompany({phone:this.registerForm.username})
    .then(res=>{
      this.companyId = res.data[0].companyId
    })
    .catch(err=>{

    })
  },
  methods:{
    changePassword(type){
      this.changeType = type
      this.dialogTableVisible = true
    },
    dialogClose(){
      this.dialogTableVisible = false
    },
    getSendCode(phone) {
      if (this.countDown) return;
      if (!/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[0-9])[0-9]{8}$/.test(phone)) {
        phone = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: phone }, { showLoading: false }).then((res) => {
        this.countDown = 60;
        this.timer = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(this.timer);
          }
        }, 1 * 1000);
        console.log(res);
        this.registerForm.companyId = res.data[0].companyId;
        this.registerForm.companyName = res.data[0].companyName;
      });
      api
        .fetchVeriCode({ phone: phone, type: 1 }, { showLoading: false })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    changeTypeTap(){
       this.changeType = 'change'
    },
    nextStep(){
      debugger
      switch(this.active){
        case 0:
          if(!this.verificationCode){
            return this.$message.error('请输入验证码')
          }
          this.verificationPhoe()
          break;
        case 1:
          if(!this.passwordForm.newPassword){
            return this.$message.error('请输入新密码')
          }
          if(!this.passwordForm.repetitionPassword){
            return this.$message.error('请重复输入新密码')
          }
          if(this.passwordForm.repetitionPassword !== this.passwordForm.newPassword){
            return this.$message.error('两次输入内容不一样，请重新输入')
          }
          this.updatePwd()
          break;
        case 3:
          this.dialogTableVisible = false
          break;
        default:
          break;
      }
    },
    verificationPhoe(){
      let data = {
        phone:this.registerForm.username,
        smsCode:this.verificationCode,
        type: 1
      }
      api.checkSms(data, { showLoading: true })
      .then(res=>{
        console.log(res)
        if(res.data){
          this.signature = res.data 
          this.active = 1
        }
      })
      .catch(err=>{
        console.log(err)
      })
    },
    //验证码修改密码
    updatePwd(){
      let data = {
        password:this.passwordForm.newPassword,
        signature:this.signature,
        companyId:this.companyId 
      }
      api.updatePwd(data, { showLoading: true })
      .then(res=>{
        console.log(res)
        if(res.code == 200){
          this.active = 3
          this.$message.success('修改成功')
          setTimeout(()=>{
            utils.clearLoginInfo()
          },1000)
        }
      })
      .catch(err=>{
        console.log(err)
      })
    },
    modifyPwd(){
      if(!this.oldPassword){
        return this.$message.error('请输入旧密码')
      }
      if(!this.setPasswordForm.newPassword){
        return this.$message.error('请输入新密码')
      }
      if(!this.setPasswordForm.repetitionPassword){
        return this.$message.error('请重复输入新密码')
      }
      if(this.setPasswordForm.repetitionPassword !== this.setPasswordForm.newPassword){
        return this.$message.error('两次输入内容不一样，请重新输入')
      }
      let data = {
        oldPassword:this.oldPassword,
        password:this.setPasswordForm.newPassword,
        phone:this.registerForm.username,
        companyId:this.companyId
      }
      api.modifyPwd(data, { showLoading: true })
      .then(res=>{
        console.log(res)
        if(res.code == 200){
          this.changeType = 'set'
          this.active = 3
          this.$message.success('修改成功')
          setTimeout(()=>{
            utils.clearLoginInfo()
          },1000)
        }
      })
      .catch(err=>{
        console.log(err)
      })
    }
  }
};
</script>

<style scoped lang='scss'>
/deep/.el-steps--simple{
  background: #fff;
}

/deep/.el-step__head.is-success{
  color: #0D6DFF;
  border-color: #0D6DFF;
}
/deep/.el-step__title.is-success{
  color: #0D6DFF;
}
.container{
  background: #fff;
  padding: 30px;
  padding-bottom: 150px;
}
</style>